import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MarblegalleryService {

  constructor(private httpService: HttpService) { }

  showgallery(postData: any)
  {
    
      return this.httpService.post('fetchmarblelistgallery', postData);
  }
}
