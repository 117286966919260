import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MarblelistService {
  
  constructor(private httpService: HttpService) { }

  showpage(postData: any)
  {
    
      return this.httpService.post('fetchmarblelist', postData);
  }
  showpageinfo(postData: any)
  {
    
      return this.httpService.post('fetchmarbleinfo', postData);
  }
  showlistapp(postData: any)
  {
    
      return this.httpService.post('getapplicationimages', postData);
  }
}
