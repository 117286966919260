import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FeedService {
  postmydata={
    detailid:''
  }
  feedData$ = new BehaviorSubject<any>([]);
  constructor(private httpService: HttpService) {}

  changeFeedData(data: any) {
    this.feedData$.next(data);
  }
  appendFeedData(data: any) {
  
   
    this.feedData$.next(data);
    
  }

  getCurrentFeedData() {
   
    return this.feedData$.getValue();
  }

  getclientinfo(postData: any)
  {
    
      return this.httpService.post('getmobilenumberinfo', postData);
  }
  listofmapping(postData: any)
  {
    
      return this.httpService.post('listofmapping', postData);
  }
  addphonenumbers(postData: any)
  {
    
      return this.httpService.post('addphonenumbers', postData);
  }

  fetchsetting(postData: any)
  {
    
      return this.httpService.post('settings', postData);
  }
  updateFeedData(newFeed: any) {
    let data = [];
    data.push(newFeed);
    let currentFeedData = this.getCurrentFeedData();
    let newFeedUpdateData = data.concat(currentFeedData);
    this.changeFeedData(newFeedUpdateData);
  }

  
  deleteFeedData(msgIndex: number) {
    let data = [];
    let currentFeedData = this.getCurrentFeedData();
    currentFeedData.splice(msgIndex, 1);
    let newFeedUpdateData = data.concat(currentFeedData);
    this.changeFeedData(newFeedUpdateData);
  }
  loadcontacts(postData: any): Observable<any> {
    
    return this.httpService.post('loadcontacts', postData);
  }
  feedData(postData: any): Observable<any> {
    
    return this.httpService.post('feed', postData);
  }
  addleadapi(postData: any): Observable<any> {
    
    return this.httpService.post('addleadapi', postData);
  }
  addarchitectapi(postData: any): Observable<any> {
    
    return this.httpService.post('addarchitectapi', postData);
  }
  addbussinessaccountapi(postData: any): Observable<any> {
    
    return this.httpService.post('addbussinessaccountapi', postData);
  }
  editleadapi(postData: any): Observable<any> {
    
    return this.httpService.post('editleadapi', postData);
  }
  editarchitectapi(postData: any): Observable<any> {
    
    return this.httpService.post('editarchitectapi', postData);
  }
  editbussinessaccountapi(postData: any): Observable<any> {
    
    return this.httpService.post('editbussinessaccountapi', postData);
  }
  addnewleadform(postData: any): Observable<any> {
    
    return this.httpService.post('addnewleadform', postData);
  }
  editnewleadform(postData: any): Observable<any> {
    
    return this.httpService.post('editnewleadform', postData);
  }
  addnewarchitectform(postData: any): Observable<any> {
    
    return this.httpService.post('addnewarchitectform', postData);
  }
  editnewarchitectform(postData: any): Observable<any> {
    
    return this.httpService.post('editnewarchitectform', postData);
  }
  addnewbussinessaccountform(postData: any): Observable<any> {
    
    return this.httpService.post('addnewbussinessaccountform', postData);
  }
  editnewbussinessaccountform(postData: any): Observable<any> {
    
    return this.httpService.post('editnewbussinessaccountform', postData);
  }
  getrequestedsample(postData: any): Observable<any> {
    
    return this.httpService.post('lgsamplerequest', postData);
  }
  listarchitect(postData: any): Observable<any> {
    
    return this.httpService.post('getallarchitect', postData);
  }
  listarchitectdetails(postData: any): Observable<any> {
    
    return this.httpService.post('architectdetails', postData);
  }
  listmappingdetails(postData: any): Observable<any> {
    
    return this.httpService.post('mappingdetails', postData);
  }
  listbussinessaccountdetails(postData: any): Observable<any> {
    
    return this.httpService.post('bussinessaccountdetails', postData);
  }
  listbussinessaccount(postData: any): Observable<any> {
    
    return this.httpService.post('getallbussinessaccount', postData);
  }
  listlead(postData: any): Observable<any> {
    
    return this.httpService.post('getallleads', postData);
  }
  listleaddetails(postData: any): Observable<any> {
    
    return this.httpService.post('leaddetails', postData);
  }
  movementmarble(postData: any): Observable<any> {
    
    return this.httpService.post('marblemovement', postData);
  }
  addactivityinfo(postData: any): Observable<any> {
    
    return this.httpService.post('addactivitylog', postData);
  }
  uploadlotimg(postData: any): Observable<any> {
    
    return this.httpService.post('uploadlotimg', postData);
  }
  getclient(postData: any): Observable<any> {
    
    return this.httpService.post('getclient', postData);
  }
  entryregchange(postData: any): Observable<any> {
    
    return this.httpService.post('entryregchange', postData);
  }
  updatemarblestatus(postData: any): Observable<any> {
    
    return this.httpService.post('updateslabs', postData);
  }
  addexistingclient(postData: any): Observable<any> {
    
    return this.httpService.post('addexistingclient', postData);
  }
  entryregister(postData: any): Observable<any> {
    
    return this.httpService.post('entryregister', postData);
  }
  otherrequestlist(postData: any): Observable<any> {
    
    return this.httpService.post('otherrequestlist', postData);
  }
  otherrequestdelete(postData: any): Observable<any> {
    
    return this.httpService.post('otherrequestdelete', postData);
  }
  samplerequestdelete(postData: any): Observable<any> {
    
    return this.httpService.post('samplerequestdelete', postData);
  }
  addothersample(postData: any): Observable<any> {
    
    return this.httpService.post('otherrequest', postData);
  }
  galleryData(postData: any): Observable<any> {
    
    return this.httpService.post('videogallery', postData);
  }
  enquiryData(postData: any): Observable<any> {
    
    return this.httpService.post('marbleenquiry', postData);
  }
  getclients(postData: any): Observable<any> {
    
    return this.httpService.post('getcontacts', postData);
  }
  getleads(postData: any): Observable<any> {
    
    return this.httpService.post('getleads', postData);
  }
  getlotlist(postData: any): Observable<any> {
    
    return this.httpService.post('get_lot_numbers', postData);
  }
  savemarblenames(postData: any): Observable<any> {
    
    return this.httpService.post('savemarblenames', postData);
  }
  gettourleadinfo(postData: any): Observable<any> {
    
    return this.httpService.post('gettourleadinfo', postData);
  }
  finishtour(postData: any): Observable<any> {
    
    return this.httpService.post('finishtour', postData);
  }
  
  getgallerylist(postData: any): Observable<any> {
    
    return this.httpService.post('getgallerydetails', postData);
  }
  savetour(postData: any): Observable<any> {
    
    return this.httpService.post('savetour', postData);
  }
  tourdata(postData: any): Observable<any> {
    
    return this.httpService.post('gettour', postData);
  }
  gettourdetails(postData: any): Observable<any> {
    
    return this.httpService.post('gettourdetails', postData);
  }
  marbleinnegotiation(postData: any): Observable<any> {
    
    return this.httpService.post('marbleinnegotiation', postData);
  }
  deletetourmarble(postData: any): Observable<any> {
    
    return this.httpService.post('deletetourmarble', postData);
  }
  
  favoriteData(postData: any): Observable<any> {
    
    return this.httpService.post('getmyfavorite', postData);
  }
  favoriteDataaction(postData: any): Observable<any> {
    
    return this.httpService.post('addfavorite', postData);
  }
  
  feedDelete(postData: any): Observable<any> {
    return this.httpService.post('feedDelete', postData);
  }
  getdashboard(postData: any): Observable<any> {
    return this.httpService.post('getdashboard', postData);
  }
  getappdashboard(postData: any): Observable<any> {
    return this.httpService.post('getapplications', postData);
  }
  
  getlotdetailinfo(postData: any): Observable<any> {
    return this.httpService.post('listofslabs', postData);
  }
  getsampledetailinfo(postData: any): Observable<any> {
    return this.httpService.post('listofsample', postData);
  }
  getslabdetailinfo(postData: any): Observable<any> {
    return this.httpService.post('slabinfodetails', postData);
  }
  
  getlotinfo(postData: any): Observable<any> {
    return this.httpService.post('listoflots', postData);
  }
  getenquirydetails(postData: any): Observable<any> {
    return this.httpService.post('getenquirydetails', postData);
  }
  savetoken(tokenData: any): Observable<any> {
    return this.httpService.post('gettoken', tokenData);
  }
  feedUpdate(postData: any): Observable<any> {
    return this.httpService.post('gettoken', postData);
  }
}
