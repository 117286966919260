import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { StorageService } from './../services/storage.service';
//import { FeedService } from 'src/app/services/feed.service';

import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from './../services/toast.service';
import { Router } from '@angular/router';

import{ModalController} from '@ionic/angular';
@Component({
  selector: 'app-modalpopup',
  templateUrl: './modalpopup.page.html',
  styleUrls: ['./modalpopup.page.scss'],
})
@Injectable({
  providedIn: 'root'
})
export class ModalpopupPage implements OnInit {
  feedData$ = new BehaviorSubject<any>([]);
  //postData:any={};
  postdatastore:any;
  postjson:any={};
  public platforms = [];
  public posttype = [];
  postData = {
    user_id: '',
    token: '',
    social_post: localStorage.getItem('social_post'),
    mediatype: localStorage.getItem('post_type'),
    fromdate: '',
    todate: '',
    lastid: ''
  }; 
  constructor(private auth: AuthService,private toastService: ToastService,private modalController:ModalController, private storageService: StorageService, private httpService: HttpService,private Router: Router) { }

  ngOnInit() {
    
  }
  closepopup()
  {
   
    this.modalController.dismiss();

  }
  
  filterdata()
  {
    
    if (typeof this.postData.social_post == 'undefined') {
      // your code here'
      this.postData.social_post="";
    }
    if (typeof this.postData.mediatype == 'undefined') {
      // your code here'
      this.postData.mediatype="";
    }
    if (typeof this.postData.fromdate == 'undefined') {
      // your code here'
      this.postData.fromdate="";
    }
    if (typeof this.postData.todate == 'undefined') {
      // your code here'
      this.postData.todate="";
    }

    localStorage.setItem('social_post',this.postData.social_post);
    localStorage.setItem('post_type',this.postData.mediatype);
    localStorage.setItem('fromdate',this.postData.fromdate);
    localStorage.setItem('todate',this.postData.todate);
    localStorage.setItem('isfilter','yes');
    

    

    localStorage.setItem('last_id',"");

    

    
    if(this.Router.url=="/home/feedfilter")
    {
     
      this.Router.navigate(['home/feed']);
    }
    else{
      
      this.Router.navigate(['home/feedfilter']);
    }
           //this.storageService.set('platforms',"yes");
         
           //this.feedService.changeFeedData(res.postData);
          

     
    
    this.modalController.dismiss();
    
  }
  
}
