import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class MarbleenquiryService {

  constructor(private httpService: HttpService) { }

  showenquiryform(postData: any)
  {
    
      return this.httpService.post('fetchenquirymarble', postData);
  }
  submitenquiry(postData: any)
  {
      return this.httpService.post('submitenquiry', postData);
  }
  sampleresponse(postData: any)
  {
      return this.httpService.post('sampleresponse', postData);
  }
  otherrequestresponse(postData: any)
  {
      return this.httpService.post('otherresponse', postData);
  }
  updateprofile(postData: any)
  {
      return this.httpService.post('updateprofile', postData);
  }
  changepassword(postData: any)
  {
    return this.httpService.post('changepassword', postData);
  }
  overallenquiry(postData: any)
  {
    return this.httpService.post('overallenquiry', postData);
  }
  samplerequest(postData: any)
  {
    return this.httpService.post('samplerequest', postData);
  }
  getmarbles(postData: any)
  {
    return this.httpService.post('getmarblenames', postData);
  }
  

}
